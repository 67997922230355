<template>
    <v-container justify="center">
        <v-layout wrap>
            <v-flex fluid > 
                <v-card outlined >
                    <v-row justify="center" >
                        <v-card-title>
                            <p class="font-weight-black" >
                            Menu Equipe Mult
                            </p>
                        </v-card-title>
                    </v-row>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
export default {
    name: "evolucao_equipe_multi",
}
</script>